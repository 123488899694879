import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
const baseUrl: any = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}
  login(login){
    return this.http.post(baseUrl + '/login',login);
  }
  chnagePassword(login){
    return this.http.post(baseUrl + '/resetpasswordkey', login);
  }
  changePdf(login){
    return this.http.post(baseUrl + '/update_pdf_type_by_org_id', login);
  }
  //Role 
  AddRole(data){
    return this.http.post( baseUrl +'/role',data);
  }
  RoleList(){
    return this.http.post( baseUrl + '/role_list','');
  }
  GetEditRole(data){
    return this.http.post( baseUrl +'/role_edit',data);
  }
  UpdateEditRole(data){
    return this.http.post( baseUrl +'/role_update',data);
  }
  DeleteRole(data){
    return this.http.post( baseUrl + '/role_delete',data);
  }
  // Admin
  AdminList(){
    return this.http.post(baseUrl+'/admin_list_view','');
  }
  AddNewAdmin(data){
    return this.http.post( baseUrl + '/register',data);
  }
  AdminEdit(data){
    return this.http.post( baseUrl + '/admin_edit',data);
  }
  UpdateAdmin(data){
    return this.http.post( baseUrl + '/admin_update',data);
  }
  DeleteAdmin(data){
    return this.http.post(baseUrl + '/admin_delete',data);
  }
  // Users
  UserList(){
    return this.http.post(baseUrl +'/user_list','');
  }
  AddUser(data){
    return this.http.post( baseUrl +'/user_register',data);
  }
  SingleUser(data){
    return this.http.post(baseUrl +'/user_edit',data);
  }
  UpdateUser(data){
    return this.http.post(baseUrl +'/user_update',data);
  }
  User_Delete(data){
    return this.http.post( baseUrl +'/user_delete',data);
  }
  OrgList(){
    return this.http.post( baseUrl +'/organization_list','');
  }
  updateIsAssessmentOnlyOrg(data: any) {
    return this.http.post(`${baseUrl}/organization_assessment_status`, data);
  }
  // Organization
 FullList(){
   return this.http.post( baseUrl +'/organization_list_view','');
 }
 // Add New Organization
 AddOrganization(data){
  return this.http.post(baseUrl +'/organization_register',data);
 }
 OrganizationEdit(data){
   return this.http.post(baseUrl +'/organization_edit',data);
 }
 UpdateOrganization(data){
   return this.http.post(baseUrl +'/organization_update',data)
 }
 // delete organization
 DeleteOrganization(data){
   return this.http.post(baseUrl+'/organization_delete',data)
 }
 // Field list
 AllFieldList(data){
   return this.http.post(baseUrl + '/organizationfieldlist',data);
 }
 // Add new field
 AddNewInput(data){
   return this.http.post(baseUrl +'/dynamicfieldadd',data);
 }
 // disable field
 DisableOrgField(data){
   return this.http.post(baseUrl+'/organizationfieldupdate',data);
 }
 DeleteOrgField(data){
  return this.http.post(baseUrl+'/organizationfielddelete',data);
}
getTypeFields(data){
  return this.http.post(baseUrl+'/view_values_by_org_filter_id',data);
}
AddNewValue(data){
  return this.http.post(baseUrl +'/add_value_by_org_filter_id',data);
}
updateNewValue(data){
  return this.http.post(baseUrl +'/update_value_by_answer_id',data);
}
DeleteOrgFieldValue(data){
  return this.http.post(baseUrl+'/delete_value_by_answer_id',data);
}

 
 // Dimension menu
 dimension_menu(){
   return this.http.post(baseUrl +'/growthChallangemainmenu','');
 }
 //dimensions
 dimension_submenu(data){
   return this.http.post( baseUrl+'/growthChallangesubmainmenu',data);
 }
 // Article Add
 Add_Article(data){
   return this.http.post( baseUrl+'/article_insert',data);
 }
 // Article List
 Article_list(){
   return this.http.post(baseUrl+'/article_list','');
 }
 //Article Edit
 EditArticle(data){
   return this.http.post(baseUrl+'/article_edit',data);
 }
 UpdateArticle(data){
  return this.http.post( baseUrl+'/article_update',data)
 }
 Deletearticle(data){
  return this.http.post(baseUrl+'/article_delete',data);
 }
 //quotes
 quotesList(){
  return this.http.post(baseUrl+'/quotes_list','');
}
addQuote(data, url){
  return this.http.post(baseUrl+'/' + url, data);
}
editQuote(data){
  return this.http.post(baseUrl+'/quotes_edit', data);
}
deleteQuote(data){
  return this.http.post(baseUrl+'/quotes_delete', data);
}
 //quotes
 growthList(){
  return this.http.post(baseUrl+'/growth_challenge_list','');
}
addGrowth(data, url){
  return this.http.post(baseUrl+'/' + url, data);
}
editGrowth(data){
  return this.http.post(baseUrl+'/growth_challenge_edit', data);
}
deleteGrowth(data){
  return this.http.post(baseUrl+'/growth_challenge_delete', data);
}
 // video list
 Video_list(){
   return this.http.post( baseUrl + '/video_list','');
 }
 Add_video(data){
   return this.http.post( baseUrl+'/video_insert',data);
 }
 Edit_video(data){
  return this.http.post( baseUrl+'/video_edit',data);
 }
 Update_video(data){
  return this.http.post( baseUrl+'/video_update',data);
 }
 Delete_video(data){
   return this.http.post( baseUrl+'/video_delete',data);
 }
 //Delete user list
 DeleteUserList(){
   return this.http.post( baseUrl +'/requestuser','');
 }
 DeleteSingleUser(data){
  return this.http.post( baseUrl+'/requestbyremoveuser',data);
 }
// add quotation
AddNewQuotation(data){
  return this.http.post(baseUrl+'/quotation_insert',data);
}
QuotationList(){
  return this.http.post(baseUrl+'/quotation_list','');
}
QuotationEditData(data){
  return this.http.post(baseUrl+'/quotation_edit',data);
}
DeteleQuotation(data){
  return this.http.post(baseUrl+'/quotation_delete',data);
}
UpdateQuotation(data){
  return this.http.post(baseUrl+'/quotation_update',data);
}
}

